* {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box; 
    font-family: 'Montserrat', sans-serif;
    
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@700&family=Montserrat:wght@400;500;600;700;800;900&display=swap');

.box{
    min-height: 90vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
